import { Dropdown } from "react-bootstrap";
import { IoChevronBackOutline, IoMenuOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./mobileheader.scss";

function MobileHeader(props) {
  const navigate = useNavigate();
  return (
    <div className="mobile-header">
      <IoChevronBackOutline
        onClick={
          props.back === "reset"
            ? () => props.action(false)
            : () => navigate(-1)
        }
        style={{ fontSize: "22px", cursor: "pointer" }}
      />
      <text style={{ fontSize: 14, fontWeight: "700" }}>
        {props.title} {props.nextTitle}
      </text>
      <Dropdown>
        <Dropdown.Toggle>
          <IoMenuOutline style={{ color: "black", fontSize: "22px" }} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#/action-3">İletişim</Dropdown.Item>
          <Dropdown.Item href="#/action-1">Hakkımızda</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Fiyatlandırma</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/")}>Giriş Yap</Dropdown.Item>
          <Dropdown.Item href="#/action-3">
            <text style={{ color: "#da3831" }}>İade Noktaları</text>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default MobileHeader;
