import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./header.css";

function Header() {
  const navigate = useNavigate();
  const navbarStyle = {
    background: "linear-gradient(to right, #e74c3c, #d66054)",
    height: 100,
  };

  return (
    <Navbar style={navbarStyle} bg="white" expand="lg">
      <Container style={{ maxWidth: "80%" }} fluid>
        <Navbar.Brand>
          {/* <img src={logo} width={150} /> */}
          <text style={{ color: 'white', fontWeight: '800', fontSize: '24px', lineHeight: '29.26px', fontFamily: 'Montserrat', fontStyle: 'italic' }}>HANDS</text>
          <text style={{ color: 'white', fontWeight: '300', fontSize: '24px', lineHeight: '29.26px', fontFamily: 'Montserrat', fontStyle: 'italic' }}>FREE</text>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
          ></Nav>
          
          {/* <Link className="header-link text-black nav-link" to="/home" href="#">
            İletişim
          </Link>
          <Link
            className="header-link text-black nav-link"
            to="/home"
            href="#action1"
          >
            Hakkımızda
          </Link>
          <Link
            className="header-link text-black nav-link"
            to="/home"
            href="#action2"
          >
            Fiyatlandırma
          </Link> */}
          <Link
            style={{ color: "white" }}
            className="header-link  nav-link"
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
            }}
            to="/"
          >
            Çıkış Yap
          </Link>
          {/* <Button
            style={{ color: "white", backgroundColor: "#DA3831" }}
            className="me-3 rounded-pill"
            variant="danger"
          >
            İade Noktaları
          </Button> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
