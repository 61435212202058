// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
  height: 100vh;
  background: linear-gradient(to right, #e74c3c, #d66054);
}
.login .text-center {
  margin-bottom: 2rem;
}
.login h3 {
  color: white;
}
.login .form-group {
  margin-bottom: 1rem;
}
.login .form-control {
  width: 100%;
  margin-bottom: 10px;
}
.login .btn-login {
  border: none;
  width: 100%; /* Make the button full width */
  font-weight: 600;
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/loginpage/loginpage.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uDAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAME;EACE,WAAA;EACA,mBAAA;AAJJ;AAOE;EACE,YAAA;EACA,WAAA,EAAA,+BAAA;EACA,gBAAA;EACA,gBAAA;AALJ","sourcesContent":["// loginpage.scss\r\n\r\n.login {\r\n  height: 100vh;\r\n  background: linear-gradient(to right, #e74c3c, #d66054);\r\n\r\n  .text-center {\r\n    margin-bottom: 2rem;\r\n  }\r\n\r\n  h3 {\r\n    color: white;\r\n  }\r\n\r\n  .form-group {\r\n    margin-bottom: 1rem;\r\n  }\r\n\r\n  .form-control {\r\n    width: 100%;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .btn-login {\r\n    border: none;\r\n    width: 100%; /* Make the button full width */\r\n    font-weight: 600;\r\n    margin-top: 50px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
