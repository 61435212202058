import React from 'react';
import { Button } from '@mui/material';

const ClearButton = ({ onClick }) => {

    return (
        <>
            <Button
                sx={{
                    marginRight: '8px',
                    color: 'black',
                    borderColor: 'black',
                    fontWeight: 400,
                    fontSize:14,
                    fontFamily:'Inter',
                    letterSpacing:0.4,
                    '&:hover': {
                        borderColor: 'black',
                    },
                    width:94,
                    height:40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap:'6px'
                }}
                variant="outlined"
                onClick={onClick}
            >
                TEMİZLE
            </Button>
        </>
    );
};

export default ClearButton;