import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import MobileHeader from "../../components/layout/mobile-header/MobileHeader";
import { Pagination, Spinner, Modal } from "react-bootstrap";
import "./handsfree.scss";
import Services from "../../services/Services";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StatusList } from "../../types/statusTypes";
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";
import TableHeader from "../../components/tableHeader/TableHeader";
import { filter } from "lodash";
import ClearButton from "../../components/shared/ClearButton";
import FilterButton from "../../components/shared/FilterButton";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function HandsfreePage() {
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [allDatas, setAllDatas] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeliverModal, setShowDeliverModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [showFailureDeliverModal, setShowFailureDeliverModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [otpCode, setOtpCode] = useState("");
  const [deliveryPassword, setDeliveryPassword] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [currentStatuses, setCurrentStatuses] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("date");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");

  const [storeName, setStoreName] = useState("");
  const [packageCode, setPackageCode] = useState("");
  const [note, setNote] = useState("");
  const [createdStartDate, setCreatedStartDate] = useState();
  const [createdEndDate, setCreatedEndDate] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");

  // for pagination
  const [page, setPage] = useState(0);

  const clearFilters = () => {
    setAllDatas(originalData);
    setStoreName("");
    setPackageCode("");
    setNote("");
    setSelectedStatus("");
    setCreatedStartDate(null);
    setCreatedEndDate(null);
  };

  const getFilteredDatas = () => {
    let filteredDataList;
    if (
      storeName ||
      packageCode ||
      note ||
      selectedStatus ||
      (createdStartDate && createdEndDate)
    ) {
      filteredDataList = originalData.filter((item) => {
        const storeNameMatch = storeName
          ? item.branchTitle.toLowerCase().includes(storeName.toLowerCase())
          : true;
        const packageCodeMatch = packageCode
          ? item.orderNumber.toLowerCase().includes(packageCode.toLowerCase())
          : true;
        const noteMatch = note
          ? item.additionalNote.toLowerCase().includes(note.toLowerCase())
          : true;
        const statusMatch = selectedStatus
          ? item.status.toString() === selectedStatus.toString()
          : true;

        console.log(item.status, selectedStatus, "??");
        const createdDateMatch =
          createdStartDate && createdEndDate
            ? moment.unix(item.createdDateTime).format("DD-MM-YYYY") >=
                moment(createdStartDate).format("DD-MM-YYYY") &&
              moment.unix(item.createdDateTime).format("DD-MM-YYYY") <=
                moment(createdEndDate).format("DD-MM-YYYY")
            : true;

        return (
          storeNameMatch &&
          packageCodeMatch &&
          noteMatch &&
          statusMatch &&
          createdDateMatch
        );
      });
    } else {
      filteredDataList = originalData;
    }

    setAllDatas(filteredDataList);
  };

  const TABLE_HEAD = [
    { id: "no", label: "NO", alignRight: false },
    { id: "store", label: "MAĞAZA", alignRight: false },
    { id: "packageCode", label: "PAKET KODU", alignRight: false },
    { id: "note", label: "NOT", alignRight: false },
    { id: "date", label: "TARİH", alignRight: false },
    { id: "status", label: "DURUMU", alignRight: false },
    { id: "operation", label: "İŞLEM", alignRight: false },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allDatas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allDatas.length) : 0;

  const filteredOrders = applySortFilter(
    allDatas,
    getComparator(order, orderBy),
    filterName
  );
  console.log(filteredOrders, "Filtered Orders");
  const isNotFound = !filteredOrders.length && !!filterName;

  //----------------------------------------------------------

  const getStatuses = (items) => {
    const uniqueStatuses = [...new Set(items.map((item) => item.status))];
    return uniqueStatuses;
  };

  const getStatusLabel = (statusId) => {
    const foundStatus = StatusList.find(
      (status) => status.id === parseInt(statusId)
    );
    return foundStatus
      ? foundStatus
      : { id: statusId, label: "Bilinmeyen Durum" };
  };

  const findStatusTitle = (status) => {
    const item = StatusList.find((row) => row.id === +status);
    return item.label;
  };

  const handleClose = (type) => {
    if (type === "received") {
      setShowModal(false);
    } else {
      setShowDeliverModal(false);
    }
    setSelectedItem(null);
  };
  const handleShow = (type) => {
    if (type === "received") {
      setShowModal(true);
    } else {
      setShowDeliverModal(true);
    }
  };

  const handleReceived = (id) => {
    setSelectedItem(id);
    handleShow("received");
  };

  const handleDelivered = (item) => {
    setSelectedItem(item.id);
    setDeliveryPassword(item.deliveryPassword);
    setSelectedPost(item);
    handleShow("delivered");
  };

  const handleComplete = (type) => {
    setButtonLoading(true);
    if (type === "received") {
      Services.SetInBranch(token, selectedItem)
        .then((res) => {
          console.log("response: ", res.data);
          if (res.data.status) {
            setShowModal(false);
            handleClose("received");
            fetchDatas();
            setSelectedItem(null);
            setShowSuccessModal(true);
          } else {
            setShowModal(false);
            setShowFailureModal(true);
          }
        })
        .catch((err) => {
          setShowModal(false);
          setShowFailureModal(true);
        })
        .finally(() => setButtonLoading(false));
    } else {
      console.log("datalar: ", selectedPost.postID, otpCode);
      Services.PostComplete(token, selectedPost.postID, otpCode)
        .then((res) => {
          console.log("response: ", res.data);
          if (res.data.status) {
            setShowDeliverModal(false);
            handleClose("deliver");
            fetchDatas();
            setSelectedItem(null);
            setShowSuccessModal(true);
          } else {
            setShowDeliverModal(false);
            setShowFailureDeliverModal(true);
          }
        })
        .catch((err) => {
          setShowDeliverModal(false);
          setShowFailureDeliverModal(true);
        })
        .finally(() => setButtonLoading(false));
    }
  };
  const fetchDatas = () => {
    setLoading(true);
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
    Services.GetPosts(storedToken, [1, 2, 3, 4, 5, 6, 7, 8, 9])
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setAllDatas(
            res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            )
          );
          setOriginalData(
            res.data.result.sort(
              (a, b) => b.createdDateTime - a.createdDateTime
            )
          );
          const uniqueStatuses = getStatuses(res.data.result);
          var currentStatusesInFunc = [];
          for (let i = 0; i < uniqueStatuses.length; i++) {
            currentStatusesInFunc.push(getStatusLabel(uniqueStatuses[i]));
          }
          setCurrentStatuses(currentStatusesInFunc);
        } else {
          setAllDatas([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setAllDatas([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const controlToken = () => {
      const expired = localStorage.getItem("expired");
      const currentTime = Math.floor(Date.now() / 1000);
      const timeWithOffset = currentTime + 20 * 60;

      if (expired && parseInt(expired) > timeWithOffset) {
        fetchDatas();
      } else {
        navigate("/");
      }
    };
    controlToken();
  }, [navigate]);

  return (
    <div>
      <Layout>
        {width <= 550 && (
          <MobileHeader
            title={"test"}
            back={"reset"}
            action={(e) => console.log(e)}
          />
        )}

        <div className="center">
          <Stack
            style={{
              paddingInline: "25px",
              height: "150px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "#EBF3FE",
                height: "100px",
                width: "100%",
                borderRadius: "10px",
                justifyContent: "center",
                display: "flex",
                paddingLeft: "35px",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "21px",
                  lineHeight: "27.3px",
                  color: "#333B54",
                }}
              >
                Handsfree Gönderileri
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: "#7C8FAC",
                  letterSpacing: "-2%",
                }}
              >
                Anasayfa • Gönderiler
              </Typography>
            </div>
          </Stack>

          {loading && (
            <div
              style={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                style={{
                  borderBottomColor: "rgb(32, 101, 209)",
                  borderRightColor: "rgb(32, 101, 209)",
                  borderLeftColor: "rgb(32, 101, 209)",
                  borderTopColor: "white",
                }}
              />
            </div>
          )}

          {!loading && originalData.length === 0 && (
            <p className="text-center">Geçmiş gönderi bulunamamıştır.</p>
          )}

          {/* Table */}
          {!loading && originalData?.length > 0 && (
            <Stack style={{ paddingInline: "25px" }}>
              <Card>
                <Stack
                  direction="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  paddingY={2}
                  paddingX={2}
                >
                  <Stack
                    mr={1}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{ marginLeft: 2, marginBottom: -4 }}
                  >
                    <TextField
                      label="Mağaza"
                      variant="outlined"
                      value={storeName}
                      onChange={(e) => setStoreName(e.target.value)}
                      sx={{ mt: 2, marginRight: "8px" }}
                    />
                    <TextField
                      label="Paket Kodu"
                      variant="outlined"
                      value={packageCode}
                      onChange={(e) => setPackageCode(e.target.value)}
                      sx={{ mt: 2, marginRight: "8px" }}
                    />
                    <TextField
                      label="Not"
                      variant="outlined"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      sx={{ mt: 2, marginRight: "8px" }}
                    />

                    <FormControl
                      variant="outlined"
                      sx={{
                        mt: 2,
                        marginRight: "8px",
                        minWidth: 240,
                        maxWidth: 240,
                      }}
                    >
                      <InputLabel id="priority-store-label">Durumu</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                        input={<OutlinedInput label="Durumu" />}
                        MenuProps={MenuProps}
                      >
                        {StatusList.map((status) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Stack
                      mr={1}
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      sx={{ marginBottom: 2 }}
                      paddingY={3}
                    >
                      <div
                        style={{
                          height: "35px",
                          width: "1px",
                          backgroundColor: "rgba(107, 114, 128, 0.7)",
                          margin: "3px 15px",
                        }}
                      ></div>
                      <ClearButton onClick={() => clearFilters()} />
                      <FilterButton onClick={() => getFilteredDatas()} />
                    </Stack>
                  </Stack>
                  <Stack
                    mr={1}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    sx={{ marginBottom: 2, marginLeft: 2 }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ marginBottom: 2 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: "8px" }}
                          format="DD/MM/YYYY"
                          label="Başlangıç Tarihi"
                          value={createdStartDate}
                          onChange={(newValue) => setCreatedStartDate(newValue)}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          sx={{ mt: 2, marginRight: "8px" }}
                          format="DD/MM/YYYY"
                          label="Bitiş Tarihi"
                          value={createdEndDate}
                          onChange={(newValue) => setCreatedEndDate(newValue)}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Stack>
                </Stack>
                <TableContainer>
                  <Table>
                    <TableHeader
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={allDatas.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredOrders
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            branchTitle,
                            orderNumber,
                            additionalNote,
                            createdDateTime,
                            status,
                            apiUserType,
                            id,
                          } = row;

                          const selectedItem =
                            selected.indexOf(orderNumber) !== -1;
                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={selectedItem}
                            >
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{branchTitle}</TableCell>
                              <TableCell align="left">{orderNumber}</TableCell>
                              <TableCell align="left">
                                {additionalNote}
                              </TableCell>

                              <TableCell align="left">
                                {moment
                                  .unix(createdDateTime)
                                  .format("DD/MM/YYYY HH:mm")}
                              </TableCell>
                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    height: "100%",
                                  }}
                                >
                                  {status === "1" ||
                                  status === "10" ||
                                  status === "15" ||
                                  status === "16" ||
                                  status === "17" ||
                                  status === "21" ||
                                  status === "22" ||
                                  status === "28" ? (
                                    <span
                                      style={{
                                        backgroundColor:
                                          "rgba(10, 127, 50, 0.24)",
                                        padding: "4px 7px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        color: "rgba(10, 127, 50)",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        fontFamily: "Inter",
                                        lineHeight: "21px",
                                      }}
                                    >
                                      {findStatusTitle(status)}
                                    </span>
                                  ) : status === "3" ||
                                    status === "9" ||
                                    status === "11" ||
                                    status === "12" ||
                                    status === "13" ||
                                    status === "14" ||
                                    status === "20" ||
                                    status === "26" ? (
                                    <span
                                      style={{
                                        backgroundColor:
                                          "rgba(247, 169, 55, 0.36)",
                                        padding: "4px 7px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        color: "rgba(247, 169, 55)",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        fontFamily: "Inter",
                                        lineHeight: "21px",
                                      }}
                                    >
                                      {findStatusTitle(status)}
                                    </span>
                                  ) : status === "2" ||
                                    status === "23" ||
                                    status === "24" ||
                                    status === "25" ||
                                    status === "29" ? (
                                    <span
                                      style={{
                                        backgroundColor:
                                          "rgba(216, 27, 96, 0.4)",
                                        padding: "4px 7px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        color: "rgba(216, 27, 96)",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        fontFamily: "Inter",
                                        lineHeight: "21px",
                                      }}
                                    >
                                      {findStatusTitle(status)}
                                    </span>
                                  ) : status === "4" ||
                                    status === "5" ||
                                    status === "6" ||
                                    status === "7" ||
                                    status === "8" ||
                                    status === "19" ||
                                    status === "27" ? (
                                    <span
                                      style={{
                                        backgroundColor:
                                          "rgb(87, 90, 255, 0.4)",
                                        padding: "4px 7px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                        color: "rgb(87, 90, 255)",
                                        fontWeight: 500,
                                        fontSize: 14,
                                        fontFamily: "Inter",
                                        lineHeight: "21px",
                                      }}
                                    >
                                      {findStatusTitle(status)}
                                    </span>
                                  ) : null}
                                </div>
                              </TableCell>
                              <TableCell align="left">
                                {(status === "4" || status === "5") && (
                                  <Button
                                    sx={{
                                      width: "103px",
                                      height: "40px",
                                      borderRadius: "5px",
                                      padding: "8px, 16px, 8px, 16px",
                                      gap: "6px",
                                      backgroundColor:
                                        status === "5" ? "#079455" : "#c7a528",
                                      color: "white",
                                      display:
                                        (apiUserType !== "handsfree" ||
                                          status === "1") &&
                                        "none",
                                    }}
                                    onClick={() => {
                                      if (status === "4") {
                                        handleReceived(id);
                                      } else {
                                        handleDelivered(row);
                                      }
                                    }}
                                  >
                                    {apiUserType === "handsfree" &&
                                      (status === "4"
                                        ? "Teslim Al"
                                        : status === "5"
                                        ? "Teslim Et"
                                        : "")}
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete
                                words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allDatas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Stack>
          )}

          {!loading && originalData.length !== 0 && allDatas.length === 0 && (
            <p className="text-center" style={{ color: "gray" }}>
              Filtreye uygun gönderi bulunamamıştır.
            </p>
          )}
        </div>

        <Modal centered show={showModal} onHide={() => handleClose("received")}>
          <Modal.Header closeButton>
            <Modal.Title>Teslim Al</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <>
                <p>{`Teslim alabilmek için QR kodunu okutturunuz.`}</p>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="https://image-charts.com/chart?chs=500x500&cht=qr&chl=2924533968f2924533919s6337951893f6337951845s2885452050f2885451996s9358485516f9358485459s1135315912f1135315856s4416953639f4416953584s4635298478f4635298426s8247253259f8247253208s2899942140f2899942089s6625699554f6625699499s2169077320f2169077271s9370569289f9370569235s7738341181f7738341129s1769171065f1769171014s9043984798f9043984749s1731737052f1731737003s7558409334f7558409280s6197567200f6197567148s6561480805f6561480751s3124221476f3124221419:9815791239f9815791124:9956723952f9956723903s918335375f0918335319s44281090f0044281035s7046714820f7046714767s1935400968f1935400912s2040013596f2040013541s9728398648f9728398594s6232958631f6232958583s8998547786f8998547729s184201406f0184201349:8500068646f8500068538s1632589715f1632589604s532315962f0532315863s606365275f0606365178s7935194104f7935193988s7801372250f7801372145s9110681747f9110681636s5654910242f5654910132"
                    alt="QR Code"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={showDeliverModal} onHide={() => handleClose("deliver")}>
          <Modal.Header closeButton>
            <Modal.Title>Teslim Et</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <>
                <p>{`Bu gönderiyi teslim etmek istediğinize emin misiniz?`}</p>
                <div className="mb-3">
                  <label htmlFor="otpCode" className="form-label">
                    OTP Kodu:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="otpCode"
                    name="otpCode"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                  {/* <p>{deliveryPassword}</p> */}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={buttonLoading}
              variant="success"
              onClick={() => handleComplete("deliver")}
            >
              {buttonLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Teslim Et"
              )}
            </Button>
            <Button
              disabled={buttonLoading}
              variant="secondary"
              onClick={() => handleClose("deliver")}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFailureModal}
          onHide={() => setShowFailureModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Paket henüz teslim alınamaz. Porter işlemleri tamamlanmadı.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowFailureModal(false)}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showFailureDeliverModal}
          onHide={() => setShowFailureDeliverModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hata</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`Paket teslim edilirken bir hata oluştu.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowFailureDeliverModal(false);
              }}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Başarılı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{`İşleminiz gerçekleştirilmiştir.`}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSuccessModal(false)}
            >
              Kapat
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </div>
  );
}

export default HandsfreePage;
