import axios from "axios";
import { BASE_URL, KEY, SECRET } from ".";

export default {
  GetToken() {
    return axios({
      method: "post",
      url: `${BASE_URL}/get-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        key: KEY,
        secret: SECRET,
      },
    });
  },

  Login(token, username, password) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/login`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    });
  },

  GetPosts(token, statuses) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/get-my-locations-posts`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        status: statuses,
      },
    });
  },

  GetPostsByApiType(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/posts/get`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        status: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26,
        ],
        withPosts: true,
        apiUserType: "handsfree",
      },
    });
  },

  GetPostByBarcode(token, barcode, status) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-search-barcode`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        barcode: barcode,
        status: status,
      },
    });
  },

  GetPostByOnlyBarcode(token, barcode) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-search-barcode`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        barcode: [barcode],
      },
    });
  },

  SetInBranch(token, postID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/posts/set-in-branch`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        postID: [postID],
      },
    });
  },

  ResendCode(token, postID) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-recreate-deliver-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        postID: postID,
      },
    });
  },

  CheckCode(token, postID, code) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-check-delivery-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        postID: postID,
        code: code,
      },
    });
  },

  PostComplete(token, postID, deliveryPassword) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-set-completed`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        postID: postID,
        deliveryPassword: deliveryPassword,
      },
    });
  },

  PostReturn(token, barcode) {
    return axios({
      method: "post",
      url: `${BASE_URL}/flow/post-return-complete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        barcode: barcode,
      },
    });
  },

  GetProfile(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/users/get-profile`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  GetMalls(token) {
    return axios({
      method: "post",
      url: `${BASE_URL}/malls/get-malls`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },
};
