// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 15px;
  position: sticky;
  top: 0;
  background-color: white;
  padding-block: 10px;
  z-index: 2;
}
.mobile-header .btn {
  padding: 0px;
  border: none;
  --bs-btn-active-bg: white;
  --bs-btn-hover-bg: white;
  --bs-btn-bg: white;
}
.mobile-header .dropdown-toggle::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/mobile-header/mobileheader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,oBAAA;EACA,gBAAA;EACA,MAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACF;AAAE;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,wBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".mobile-header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  width: 100%;\r\n  padding-inline: 15px;\r\n  position: sticky;\r\n  top: 0;\r\n  background-color: white;\r\n  padding-block: 10px;\r\n  z-index: 2;\r\n  .btn {\r\n    padding: 0px;\r\n    border: none;\r\n    --bs-btn-active-bg: white;\r\n    --bs-btn-hover-bg: white;\r\n    --bs-btn-bg: white;\r\n  }\r\n  .dropdown-toggle::after {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
