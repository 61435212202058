// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #f5f5f5;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: rgb(209, 32, 32) !important;
  border-color: rgb(209, 32, 32) !important;
}

.pagination {
  --bs-pagination-color: rgb(209, 32, 32) !important;
  --bs-pagination-hover-color: rgb(209, 32, 32) !important;
}

.received-button {
  background-color: rgb(209, 32, 32) !important;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/handsfree/handsfree.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;;EAEE,sBAAA;EACA,aAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;;EAEE,UAAA;EACA,wCAAA;EACA,6CAAA;EACA,yCAAA;AACF;;AAEA;EACE,kDAAA;EACA,wDAAA;AACF;;AAEA;EACE,6CAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-top: 20px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 10px;\r\n}\r\n\r\n.table th,\r\n.table td {\r\n  border: 1px solid #ddd;\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\n.table th {\r\n  background-color: #f2f2f2;\r\n}\r\n\r\n.table tbody tr:hover {\r\n  background-color: #f5f5f5;\r\n}\r\n\r\n.active > .page-link,\r\n.page-link.active {\r\n  z-index: 3;\r\n  color: var(--bs-pagination-active-color);\r\n  background-color: rgb(209, 32, 32) !important;\r\n  border-color: rgb(209, 32, 32) !important;\r\n}\r\n\r\n.pagination {\r\n  --bs-pagination-color: rgb(209, 32, 32) !important;\r\n  --bs-pagination-hover-color: rgb(209, 32, 32) !important;\r\n}\r\n\r\n.received-button {\r\n  background-color: rgb(209, 32, 32) !important;\r\n  color: white;\r\n  border: none;\r\n  padding: 8px 16px;\r\n  cursor: pointer;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
