import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/footer.png";
import insta from "../../../assets/images/insta.png";
import twitter from "../../../assets/images/twitter.png";
import linkedin from "../../../assets/images/linkedin.png";
import appstore from "../../../assets/images/appstore.png";
import googleplay from "../../../assets/images/googleplay.png";
import "./footer.scss";
import { Col, Row, Container } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row justify-content-center">
          {/* Logolu kısım */}
          <div className="col-lg-3 col-md-12 col-sm-12 mt-2">
            <img src={Logo} alt="logo" maxWidth={600} />
            <p
              style={{
                color: "rgba(255, 255, 255, 1)",
                paddingRight: 99,
                marginTop: 10,
                marginBottom: 25,
              }}
            >
              Easy Point ile aklınız kargonuzda kalmasın.
            </p>
            <a
              href="https://www.instagram.com/easypoint.com.tr/"
              target="_blank"
            >
              <img src={insta} className="me-4" alt="" />
            </a>
            <a
              href="https://twitter.com/easypointcomtr?lang=tr"
              target="_blank"
            >
              <img src={twitter} className="me-4" alt="" />
            </a>

            <a
              href="https://www.linkedin.com/company/easy-point/"
              target="_blank"
            >
              <img src={linkedin} alt="" />
            </a>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12">
            <h4 style={{ color: "white" }}>Bize Ulaş</h4>
            <p
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                fontWeight: "400",
                marginBottom: 40,
              }}
            >
              info@easypoint.com.tr
            </p>
            <h4 style={{ color: "white" }}>Destek Hattı</h4>
            <p
              style={{
                color: "rgba(255, 255, 255, 0.6)",
                fontWeight: "400",
              }}
            >
              +90 212 257 00 70
            </p>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12">
            <div className="row ">
              <h5 style={{ color: "white", marginBottom: 23 }}>
                Mesafeli Satış Sözleşmesi
              </h5>
              <h5 style={{ color: "white", marginBottom: 23 }}>
                Kullanıcı Koşulları
              </h5>
              <h5 style={{ color: "white", marginBottom: 23 }}>
                Sıkça Sorulan Sorular
              </h5>
              <h5 style={{ color: "white" }}>Hakkımızda</h5>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="row">
              <h5 style={{ color: "white", marginBottom: 20 }}>
                Easy Point Express Uygulamasını İndir
              </h5>
              <text
                style={{ color: "rgba(255, 255, 255, 0.6)", marginBottom: 45 }}
              >
                Easy Point uygulaması ile dilediğin zaman paketini teslim al
                iade işlemlerini gerçekleştir.
              </text>
              <div>
                <a
                  href="https://apps.apple.com/us/app/easy-point/id1587679132?platform=iphone"
                  target="_blank"
                >
                  <img src={appstore} className="me-2" alt="" />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.malllogistics.easypoint&gl=TR"
                  target="_blank"
                >
                  <img src={googleplay} alt="" />
                </a>
              </div>
            </div>
          </div>
          <text
            style={{
              textAlign: "center",
              color: "rgba(255, 255, 255, 0.6)",
              marginTop: 70,
            }}
          >
            Tüm Hakları Saklıdır. © 2021 Easy Point
          </text>
        </div>
      </div>
    </div>
  );
}

export default Footer;
