// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  align-items: center;
  background-color: #171717;
  display: flex;
  width: 100%;
}
.footer .container {
  background-color: #171717 !important;
  border: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 990px) {
  .container {
    text-align: center;
  }
  .container p {
    padding-right: 0px !important;
  }
  .container .col-lg-2 {
    margin-top: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/footer/footer.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,WAAA;AACF;AAAE;EACE,oCAAA;EACA,uBAAA;AAEJ;;AAGA;EACE,eAAA;EACA,YAAA;AAAF;;AAGA;EACE;IACE,kBAAA;EAAF;EACE;IACE,6BAAA;EACJ;EACE;IACE,gBAAA;EACJ;AACF","sourcesContent":[".footer {\r\n  align-items: center;\r\n  background-color: #171717;\r\n  display: flex;\r\n  width: 100%;\r\n  .container {\r\n    background-color: #171717 !important;\r\n    border: none !important;\r\n    // min-height: 250px;\r\n  }\r\n}\r\n\r\nimg {\r\n  max-width: 100%;\r\n  height: auto;\r\n}\r\n\r\n@media (max-width: 990px) {\r\n  .container {\r\n    text-align: center;\r\n    p {\r\n      padding-right: 0px !important;\r\n    }\r\n    .col-lg-2 {\r\n      margin-top: 15px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
