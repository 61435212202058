// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
}

@media screen and (max-width: 550px) {
  .navbar {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .header-link {
    margin-bottom: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/header/header.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".header-link {\r\n  text-decoration: none;\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  padding-right: 20px;\r\n}\r\n\r\n@media screen and (max-width: 550px) {\r\n  .navbar {\r\n    display: none;\r\n  }\r\n}\r\n@media screen and (max-width: 992px) {\r\n  .header-link {\r\n    margin-bottom: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
