import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginpage/LoginPage";
import HandsfreePage from "./pages/handsfree/HandsfreePage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/handsfree" element={<HandsfreePage />} />
      </Routes>{" "}
    </>
  );
}

export default App;
