import React from 'react';
import { Button } from '@mui/material';

const FilterButton = ({ onClick }) => {

    return (
        <>
            <Button
                sx={{
                    backgroundColor: '#1570EF',
                    fontWeight: 400,
                    fontSize: 14,
                    fontFamily: 'Inter',
                    width: 97,
                    height: 40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap: '6px'
                }}
                variant="contained"
                onClick={onClick}
            >
                FİLTRELE
            </Button>
        </>
    );
};

export default FilterButton;