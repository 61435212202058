// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  align-items: center;
  justify-content: center;
}

.center {
  /* text-align: center; */
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  max-width: 1300px;
  /* min-width: 500px; */
}

.add-new {
  align-self: flex-end;
}

@media screen and (max-width: 800px) {
  .center {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uBAAA;AACF;;AACA;EACE,wBAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,iBAAA;EACA,sBAAA;AAEF;;AACA;EACE,oBAAA;AAEF;;AACA;EACE;IACE,WAAA;EAEF;AACF","sourcesContent":[".App {\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.center {\r\n  /* text-align: center; */\r\n  display: block;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: auto;\r\n  width: 80%;\r\n  max-width: 1300px;\r\n  /* min-width: 500px; */\r\n}\r\n\r\n.add-new {\r\n  align-self: flex-end;\r\n}\r\n\r\n@media screen and (max-width: 800px) {\r\n  .center {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
